import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';

export default class SeasonPassApiService extends ApiService<any> {
  constructor() {
    super('loyalty/heide-park/season-pass', 'v1');
  }

  public uploadCsv(payload: FormData): AxiosPromise<void> {
    return axios.put(`${this.getBaseUrl()}/import`, payload);
  }
}
