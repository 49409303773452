
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import VWrapper from '@/components/shared/VWrapper.vue';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import { Permission } from '@/enums/Permission';
import { CustomerGroup } from '@/enums/CustomerGroup';
import { InputType } from '@/enums/InputType';
import { User } from '@/interfaces/models/User';
import SeasonPassApiService from '@/api/http/SeasonPassApiService';

const api = new SeasonPassApiService();
const auth = namespace('auth');

@Component({
  components: { VWrapper, VFormBuilder },
})
export default class SeasonPass extends mixins(StackedForm, Notification) {
  @auth.State('user') public authUser!: User;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder>;
  };

  get seasonPassAvailable() {
    if (this.$isAdmin()) {
      return true;
    }

    if (
      !this.$can(Permission.HEIDE_PARK_SEASON_PASS_UPLOAD) ||
      this.authUser?.customerGroup !== CustomerGroup.PIRATEN_BURGER
    ) {
      return false;
    }

    return true;
  }

  get items() {
    return [
      {
        name: 'file',
        type: InputType.File,
        label: 'seasonPass.csv',
        rules: 'required',
        default: null,
        accept: 'text/csv, text/plain',
      },
    ];
  }

  public async upload() {
    if (!(await this.$refs.form.validate())) {
      return;
    }

    const formData: FormData = new FormData();
    const uploadedCsv = this.$refs.form.getData()?.file;
    const canUpload = this.seasonPassAvailable && uploadedCsv && uploadedCsv instanceof File;

    if (!canUpload) {
      return;
    }

    this.$confirm(this.$t('seasonPass.warning') as string).then(async (res: boolean) => {
      if (!res) {
        return;
      }

      this.$startLoading('seasonPass.csv');
      formData.append('file', uploadedCsv);

      await api
        .uploadCsv(formData)
        .then(() => {
          this.notifySuccess('seasonPass.successfulUpload');
          this.$stopLoading('seasonPass.csv');
        })
        .catch((e) => {
          this.$stopLoading('seasonPass.csv');
          throw e;
        });
    });
  }
}
